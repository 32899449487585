//https://www.bezkoder.com/react-login-example-jwt-hooks/

import { IsNullOrEmpty } from "../services/CustomService";
export async function switchAccount(childId) {

    let model = {
        ChildId: childId
    };

    const response = await fetch('/api/authenticate/switch', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
        },

        body: JSON.stringify(model)
    });
    const data = await response.json();
    if (data) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(data.user))
        localStorage.removeItem("subscription");
        localStorage.setItem("subscription", JSON.stringify(data.subscription))
    }
    return data;
}
export async function redirectToLogin() {
    localStorage.removeItem("subscription");
    localStorage.removeItem("user");
    window.location.replace('/login');
}
export async function login(params) {
    let source = {
        Email: params.email,
        Password: params.password
    };

    const response = await fetch('/api/authenticate/login', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(source)
    });
    const data = await response.json();
    if (data) {
        localStorage.setItem("user", JSON.stringify(data.user))
        localStorage.setItem("subscription", JSON.stringify(data.subscription))
    }
    return data;
}
export async function logout() {

    const response = await fetch('/api/authenticate/logout', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    localStorage.removeItem("subscription");
    localStorage.removeItem("user");
}
export function getCurrentUser() {

    let u = localStorage.getItem("user");
    if (IsNullOrEmpty(u) === false) {
        let user = JSON.parse(u);
        if (user) {
            return user;
        }
    }
    window.location.replace('/login');
}
export function getCurrentSubscription() {

    let subscription = JSON.parse(localStorage.getItem("subscription"));
    if (subscription) {
        return subscription;
    }
    window.location.replace('/login');
}
export function getToken() {

    const user = getCurrentUser();
    let token = '';
    if (user) {
        token = user.token;
    }
    return token;
}